import { NextPage } from 'next';
import NextErrorComponent from 'next/error';
import type { ErrorProps } from 'next/error';

interface AppErrorProps extends ErrorProps {
  err?: Error;
  hasGetInitialPropsRun?: boolean;
}

const AppError: NextPage<AppErrorProps> = (error) => {
  console.log(error);
  return <NextErrorComponent statusCode={error.statusCode} />;
};

AppError.getInitialProps = async (ctx) => {
  const errorInitialProps: AppErrorProps =
    await NextErrorComponent.getInitialProps(ctx);
  errorInitialProps.hasGetInitialPropsRun = true;
  if (ctx.err) {
    return errorInitialProps;
  }
  return errorInitialProps;
};

export default AppError;